import React, { useEffect } from 'react'
import MainContent from '../../Components/MainContent/MainContent'
// import "../../assets/css/newstyle.min.css";
// import "../../assets/css/main.min.css";

function Dashboard() {
    return (
        <>
            <MainContent /> 
        {/* <div className='dashboard'> */}

            {/* <div className='card'>
                <div className='card-body'> */}
                    {/* <h1 className='main_title_dashboard'>Dashboard</h1> */}
                {/* </div>
            </div> */}
        {/* </div> */}
        </>
    )
}

export default Dashboard
